//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Pagination from "@/components/Pagination";
import { fetchChargingDataList, fetchPropertyList, fetchEquList } from "@/api/charge";
import "@/utils/global.js"; // import {} from 'iview-area'

export default {
  name: "ChargingData",
  components: {
    Pagination: Pagination
  },
  data: function data() {
    return {
      // 查询字段
      searchForm: {
        page: 1,
        per_page: 20,
        keyword: "",
        start_time: '',
        end_time: '',
        filter: {
          charging_id: '',
          property_id: '',
          created_time: ''
        }
      },
      // 表格数据
      dataList: [],
      // 总数
      totalPage: 0,
      tableLoading: false,
      chargingLoading: false,
      chargingOptions: [],
      propertyLoading: false,
      propertyOptions: [],
      pickerOptions: _global.timeOption
    };
  },
  created: function created() {
    this.searchForm.keyword = this.$route.query ? this.$route.query.keyword : '';
    this.getTableData();
  },
  methods: {
    getTableData: function getTableData() {
      var _this = this;

      this.tableLoading = true;
      var data = this.searchForm;
      fetchChargingDataList(data).then(function (response) {
        _this.dataList = response.data;
        _this.totalPage = response.meta.total;
        _this.tableLoading = false;
      }).catch(function (err) {
        _this.tableLoading = false;

        _this.$message({
          type: "warning",
          message: err.data["message"]
        });
      });
    },
    handleSearch: function handleSearch() {
      console.log("搜索");
      this.searchForm.page = 1;
      this.getTableData();
    },
    handlePageCurrent: function handlePageCurrent(val) {
      this.searchForm.page = val;
      this.getTableData();
    },
    handlePageSize: function handlePageSize(val) {
      this.searchForm.per_page = val;
      this.searchForm.page = 1;
      this.getTableData();
    },
    handleTime: function handleTime(val) {
      if (val) {
        this.searchForm["start_time"] = val[0];
        this.searchForm["end_time"] = val[1];
      } else {
        this.searchForm["start_time"] = "";
        this.searchForm["end_time"] = "";
      }
    },
    //搜索小区
    remoteProperty: function remoteProperty(query) {
      var _this2 = this;

      if (query !== "") {
        this.propertyLoading = true;
        setTimeout(function () {
          _this2.propertyLoading = false;
          var data = {
            keyword: query
          };
          fetchPropertyList(data).then(function (res) {
            _this2.propertyOptions = res.data;
          });
        }, 100);
      } else {
        this.propertyOptions = [];
      }
    },
    //搜索充电桩
    remoteCharging: function remoteCharging(query) {
      var _this3 = this;

      if (query !== "") {
        this.chargingLoading = true;
        setTimeout(function () {
          _this3.chargingLoading = false;
          var data = {
            keyword: query
          };
          fetchEquList(data).then(function (res) {
            _this3.chargingOptions = res.data;
          });
        }, 100);
      } else {
        this.chargingOptions = [];
      }
    },
    resetForm: function resetForm(formName) {
      this.searchForm["start_time"] = "";
      this.searchForm["end_time"] = "";
      this.$refs[formName].resetFields();
    }
  }
};